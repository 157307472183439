<template>
  <div class="userinfo-container">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span style="vertical-align: middle">{{ orderInfo.name }}</span>
    </div>
    <div class="userinfo-box">
      <Loading :show="loading">
        <div data-spm="userinfo">
          <div class="next-loading next-loading-inline sc-khAkCZ jIEYGu">
            <div class="next-loading-wrap">
              <div class="sc-iBaQBe cGFAXW">
                <div class="sc-eggMyH fLwpMi">
                  <h3>用户基本信息</h3>
                  <div style="margin-left: 16px">
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="edit"
                    >
                      <span class="next-btn-helper">编辑基本信息</span>
                    </button>
                    <button
                      type="button"
                      class="next-btn next-medium next-btn-primary next-btn-text"
                      style="margin-right: 8px"
                      @click="reset"
                    >
                      <span class="next-btn-helper">重置密码</span>
                    </button>
                  </div>
                </div>
                <div class="next-row next-row-wrap wind-rc-data-fields">
                  <form-label label="登录名称">
                    {{ orderInfo.name || "-" }}
                  </form-label>
                  <form-label label="UID"> {{ orderInfo.id }} </form-label>
                  <form-label label="显示名称">
                    {{ orderInfo.display_name || "-" }}
                  </form-label>
                  <form-label label="创建时间">
                    {{ orderInfo.create_time }}
                  </form-label>
                  <form-label label="手机">
                    {{ orderInfo.phone || "-" }}
                  </form-label>
                  <form-label label="邮箱">
                    {{ orderInfo.email || "-" }}
                  </form-label>
                  <form-label label="备注">
                    {{ orderInfo.remark || "-" }}
                  </form-label>
                  <form-label label="域">
                    {{ orderInfo.domain_name || "-" }}
                  </form-label>
                  <form-label label="父级域">
                    {{ orderInfo.parent_domain_name || "-" }}
                  </form-label>
                  <form-label label="别名">
                    {{ orderInfo.alias || "-" }}
                  </form-label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next-card-title fLwpMi">
          合作伙伴&nbsp;
          <button
            type="button"
            class="next-btn next-medium next-btn-primary next-btn-text"
            style="margin-right: 8px"
            @click="cooprrateDialog"
          >
            <span class="next-btn-helper">新增合作伙伴</span>
          </button>
        </div>
        <template>
          <el-table border :data="orderInfo.partner" style="width: 100%">
            <el-table-column prop="id" label="ID" />
            <el-table-column prop="name" label="名字" />
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="remcooperate(row)"
                >
                  <span class="next-btn-helper">删除</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </Loading>
    </div>
    <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="加入的组" name="1">
        <button
          type="button"
          class="next-btn next-medium next-btn-primary"
          style="margin-right: 8px"
        >
          <span class="next-btn-helper">添加到用户组</span>
        </button>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          class="table-box"
        >
          <el-table-column
            prop="date"
            label="用户组名称/显示名称"
            width="180"
          />
          <el-table-column prop="name" label="备注" width="180" />
          <el-table-column prop="address" label="操作" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="拥有的角色" name="2">
        <button
          type="button"
          class="next-btn next-medium next-btn-primary"
          style="margin-right: 8px"
        >
          <span class="next-btn-helper">新增角色</span>
        </button>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          class="table-box"
        >
          <el-table-column
            prop="date"
            label="用户组名称/显示名称"
            width="180"
          />
          <el-table-column prop="name" label="备注" width="180" />
          <el-table-column prop="address" label="操作" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="拥有的权限" name="3">
        <button
          type="button"
          class="next-btn next-medium next-btn-primary"
          style="margin-right: 8px"
        >
          <span class="next-btn-helper">新增权限</span>
        </button>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          class="table-box"
        >
          <el-table-column
            prop="date"
            label="用户组名称/显示名称"
            width="180"
          />
          <el-table-column prop="name" label="备注" width="180" />
          <el-table-column prop="address" label="操作" />
        </el-table>
      </el-tab-pane>
    </el-tabs> -->

    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="编辑基本信息"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户登录名称" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="显示名称" prop="display_name">
            <el-input v-model="form.display_name" />
          </el-form-item>
          <el-form-item label="别名" prop="display_name">
            <el-input v-model="form.alias" />
          </el-form-item>
          <el-form-item label="父级主账号" prop="display_name">
            <el-select
              v-model="form.parent_domain_id"
              clearable
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in parentAccountList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            @click="submit('ruleForm')"
          >确定</el-button
          >
          <!-- <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submit('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button> -->
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-dialog title="编辑合作伙伴" :visible.sync="dialog" width="400px">
      <div>
        <label for="">请选择主用户 &nbsp;</label>
        <el-select
          v-model="to_main_user_id_list"
          multiple
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in cooperateList"
            :key="item.id"
            :title="
              to_main_user_id_disabled_list.includes(item.id) &&
                '不能添加自己或重复添加'
            "
            :disabled="to_main_user_id_disabled_list.includes(item.id)"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="editcooperate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  details_main_user,
  edit_main_user,
  reset_password,
  all_main_user_list,
  get_all_main_user_list,
  edit_account,
  del_account
} from '@/api'

export default {
  data () {
    return {
      to_main_user_id_disabled_list: [],
      to_main_user_id_list: [],
      cooperateList: [],
      dialog: false,
      drawer: false,
      loading: false,
      activeName: '1',
      orderInfo: {},
      tableData: [],
      parentAccountList: [],
      form: {},
      rules: {
        name: [{ required: true, message: '不能为空', trigger: 'blur' }]
      }
    }
  },
  async created () {
    this.get_info_user()
    const { data } = await all_main_user_list()
    this.parentAccountList = data
    const res = await get_all_main_user_list()
    this.cooperateList = res.data
  },

  methods: {
    cooprrateDialog () {
      this.to_main_user_id_list = []
      this.dialog = true
      this.to_main_user_id_disabled_list = [
        ...this.orderInfo.partner.map(({ id }) => id),
        Number(this.$route.query.id)
      ]
    },

    editcooperate () {
      const data = {
        from_main_user_id: Number(this.$route.query.id),
        to_main_user_id_list: this.to_main_user_id_list
      }
      edit_account(data)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.dialog = false
          this.get_info_user()
        })
        .catch(() => {
          this.dialog = false
          this.get_info_user()
        })
    },
    remcooperate (row) {
      const params = {
        from_main_user_id: Number(this.$route.query.id),
        to_main_user_id: row.id
      }

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          del_account(params).then((res) => {
            if (res.code == 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }
            this.get_info_user()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset () {
      this.$confirm('是否确认重置密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            account_type: 1,
            user_id: this.orderInfo.id
          }
          reset_password(params).then((res) => {
            const msg = `${res.msg}  ${res.data}`
            alert(msg)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置'
          })
        })
    },
    get_info_user () {
      const params = {
        user_id: this.$route.query.id
      }
      this.loading = true
      details_main_user(params).then((res) => {
        this.loading = false
        this.orderInfo = res.data
      })
    },
    edit () {
      this.drawer = true
      this.form = JSON.parse(JSON.stringify(this.orderInfo))
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            user_id: this.form.id
          }
          delete params.parent_domain_name
          edit_main_user(params).then((res) => {
            this.drawer = false
            this.get_info_user()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/main')
    }
  }
}
</script>

<style scoped>
.userinfo-container >>> .el-tabs__item {
  font-weight: 400 !important;
}
.userinfo-box {
  padding-top: 25px;
  color: #333;
}
.next-loading {
  width: 100%;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
h3,
h4 {
  margin-bottom: 7px !important;
  font-size: 14px;
}
.fLwpMi div:first-of-type {
  display: inline-block;
  margin-left: 40px;
}
.cGFAXW {
  margin-bottom: 20px;
}
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.eEgMuO {
  display: flex;
  margin-bottom: 8px;
  width: 50%;
  min-width: 300px;
}
.TJhJs {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.eRLVLq {
  margin-right: 16px;
  width: 164px;
}
</style>
